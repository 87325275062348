.homeCard {
  margin: 10px;
  border: 0px solid black;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.75);
  transition: transform 100ms ease-in;
  cursor: pointer;
}

.homeCard:hover {
  /* transform: scale(1.02); */
}

.homeCard > img {
  min-width: 300px;
  max-height: 294px;
  min-height: 200px;
  width: 100%;
}

.homeCard__info {
  margin-top: -9px;
  border-radius: 10px;
  padding: 20px;
  border: 1;
}

.homeCard__info > h2 {
  font-size: 18px;
  font-weight: 800;
}

.homeCard__info > h4 {
  font-size: 14px;
  font-weight: 300;
  margin: 8px 0px;
}
