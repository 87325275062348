.home__section {
  display: flex;
  padding: 30px;
}

@media only screen and (max-width: 600px) {
  .home__section {
    flex-wrap: wrap;
  }
}
