.header {
  display: flex;

  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  width: 97vw;
  padding: 15px 20px;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.header__icon {
  background-color: white;
  object-fit: contain;
  height: 35px;
}

.header__center {
  padding: 10px;
  height: 30px;
  border: 1px solid lightgray;
  display: flex;
  border-radius: 999px;
  justify-content: space-between;
  align-items: center;
  min-width: 250px;
}

.header__center input {
  background-color: transparent;
  flex: 1;
  padding: 2px;
  padding-left: 10px;
  font-size: medium;
  border: none;
  outline-width: 0;
}

.header__center > .MuiSvgIcon-root {
  margin-left: 5px;
  margin-right: 5px;
}
.header__right {
  min-width: 250px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (min-width: 500px) {
  .header__center input {
    min-width: 300px;
  }
}

@media only screen and (max-width: 500px) {
  .header {
    padding: 0px;
    padding-bottom: 5px;
    width: 100%;
    margin: unset;
    justify-content: flex-start;
    border-bottom: 1px solid lightgray;
  }

  .header__icon {
    margin: 20px;
    margin-bottom: 0px;
    margin-top: 15px;
  }

  .header > .header__center {
    margin: 10px;
    width: 100%;
  }

  .header__right {
    width: 100%;
  }
}
