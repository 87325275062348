.searchResults {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px;
  border-bottom: 1px solid lightgray;
}

.searchResults > img {
  border-radius: 10px;
}

.searchResults__info {
  min-width: 400px;
  padding: 20px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.searchResults__infoTop {
  display: flex;
  justify-content: space-between;
}
.searchResults__infoTop_content {
  max-width: 250px;
  color: gray;
  font-weight: 300 !important;
  padding-bottom: 30px;
}

.searchResults__infoTop_content > p {
  padding: 3px 0px;
}

.content__description {
  font-size: small;
}

.searchResults__infoTop_content > h3 {
  color: black;
  font-weight: 300;
}

.searchResults__infoBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviews-ratings {
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reviews-ratings > .MuiSvgIcon-root {
  color: #ff385c;
}

.searchResults__infoBottom > h3 {
}

@media only screen and (max-width: 500px) {
  .searchResults {
    justify-content: center;
  }
  .searchResults__info {
    min-width: unset;
    max-width: 100vw !important;
    padding-left: unset;
    padding-right: unset;
    width: 85vw;
  }
}
