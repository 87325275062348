.searchPage__info {
  padding: 20px;
}
.searchPage__info > p {
  font-size: 14px;
  margin-bottom: 10px;
}

.searchPage__info > h1 {
  margin-bottom: 15px;
}
.searchPage__info > button {
  margin: 5px;
  text-transform: inherit;
  border-radius: 999px;
}

@media only screen and (max-width: 500px) {
  .searchPage__info > h1 {
    font-size: 23px;
    margin-bottom: 10px;
  }
  .searchPage__info > button {
    margin: 5px 2px;
  }
}
